<template>
  <div style="background: white">
    <!-- <HeaderBar /> -->
    <v-sheet class="overflow-hidden">
      <Loading />
      <v-container fluid id="sub-bar">
        <v-row>
          <v-col cols="11">
            <v-btn text @click="showMenu">
              <v-icon>mdi-menu</v-icon>
            </v-btn>
            <div id="lbl-screen-name">リストパターン1</div>
          </v-col>
          <v-col cols="1">
            <v-btn text id="btn-logout" class="menu-btn" @click="logout">
              <img src="../../assets/img/logout_menu_icon.png" />ログアウト</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid>
        <v-form ref="searchForm">
          <v-row>
            <v-col cols="3">
              <v-row>
                <v-col cols="1" />
                <v-col cols="4">
                  <div class="search-label">
                    {{ $t("label.lbl_supplier") }}<span class="require">*</span>
                  </div>
                </v-col>
                <v-col cols="7">
                  <v-select
                    class="list-single"
                    v-model="suppliersSelected"
                    :items="dummy_supplierList"
                    :single-line="true"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4">
              <v-row>
                <v-col cols="1" />
                <v-col cols="5">
                  <div class="search-label">
                    {{ $t("label.lbl_arrivalScheduleNo") }}<span class="require">*</span>
                  </div>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    class="txt-single"
                    v-model="txt_arrivalScheduleNo"
                    maxlength="10"
                    placeholder="入荷予定No"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="5">
              <v-row>
                <v-col cols="1" />
                <v-col cols="4">
                  <div class="search-label">
                    {{ $t("label.lbl_arrivalScheduleDate") }}<span class="require">*</span>
                  </div>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    id="txt-delivery-date"
                    v-model="arrivalScheduleDate"
                    :single-line="true"
                    class="txt-single"
                    @change="changeDate"
                    placeholder="YYYY/MM/DD"
                    :rules="[rules.yyyymmdd]"
                    @input="formValidation"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-menu
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    class="date-calendar"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on"> キャレンダー </v-btn>
                    </template>
                    <v-date-picker
                      id="calendar-arrival-schedule-date"
                      v-model="arrivalScheduleDateCal"
                      @input="dateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3">
              <v-row>
                <v-col cols="1" />
                <v-col cols="4">
                  <div class="search-label">
                    {{ $t("label.lbl_arrival") }}<span class="require">*</span>
                  </div>
                </v-col>
                <v-col cols="7">
                  <v-select
                    id="lst-sales-office"
                    class="list-single"
                    v-model="arrivalSelected"
                    :items="dummy_arrivalList"
                    :single-line="true"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4">
              <v-row>
                <v-col cols="1" />
                <v-col cols="5">
                  <div class="search-label">
                    {{ $t("label.lbl_slipNo") }}
                  </div>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    class="txt-single"
                    v-model="txt_slipNo"
                    maxlength="10"
                    placeholder="荷主伝票番号"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="5">
              <v-row>
                <v-col cols="1" />
                <v-col cols="4">
                  <div class="search-label">
                    {{ $t("label.lbl_isPaid") }}<span class="require">*</span>
                  </div>
                </v-col>
                <v-col cols="7">
                  <v-btn-toggle v-model="isPaid" color="blue darken-2" class="toggle black--text">
                    <v-btn id="btn-pack" class="btn" value="1">
                      {{ $t("btn.btn_paid") }}
                    </v-btn>
                    <v-btn id="btn-load" class="btn" value="2">
                      {{ $t("btn.btn_free") }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3">
              <v-row>
                <v-col cols="1" />
                <v-col>
                  <v-btn id="btn-search" class="api-btn" @click="search">{{
                    $t("btn.btn_search")
                  }}</v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4" />
            <v-col cols="5">
              <v-row>
                <v-col cols="5" />
                <v-col cols="3">
                  <div class="search-label">{{ $t("label.lbl_itemsPerPage") }}</div>
                </v-col>
                <v-col cols="3">
                  <v-select
                    class="list-single"
                    v-model="itemsPerPage"
                    :items="itemsPerPageList"
                    :single-line="true"
                  />
                </v-col>
                <v-col cols="1" />
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          disable-filtering
          disable-sort
          :hide-default-footer="true"
          height="600px"
          @page-count="pageCount = $event"
        >
          <template v-slot:item.productCd="{ item }">
            <div>{{ item.productCd }}</div>
            <div>{{ item.productNm }}</div>
          </template>
          <template v-slot:item.accInfos="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="text-truncate" style="max-width: 200px" v-bind="attrs" v-on="on">
                  {{ item.accInfos }}
                </div>
              </template>
              <span>{{ item.accInfos }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.location="{ item }">
            <v-btn small @click="addLocation(item)"> {{ $t("btn.btn_insert") }} </v-btn>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
        </div>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
      />
      <!-- <v-dialog v-model="dialogLocation" :max-width="800">
        <LocationDialog
          :isOpenDetailDialog.sync="dialogLocation"
          v-on:setLocation="saveLocation"
          :locInfo="locInfo"
        />
      </v-dialog> -->
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
//import { appConfig } from "../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
//import { messsageUtil } from "../assets/scripts/js/MesssageUtil";
//import { functionAuth } from "../assets/scripts/js/FunctionAuth";
//import { screenAuth } from "../assets/scripts/js/ScreenAuth";
import { i18n } from "../../lang/lang.js";
// import HeaderBar from "@/components/HeaderBar.vue";
export default {
  name: "list1",
  components: {
    // HeaderBar,
    Loading,
    SimpleDialog,
    sideMenu,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    locInfo: {
      locProductCd: "",
      warehouse: "",
      location: "",
      productCd: "",
      qty: 0,
      init: false,
    },
    // ロケーションダイアログ
    dialogLocation: false,
    // 入荷予定日付
    arrivalScheduleDateCal: "",
    arrivalScheduleDate: "",
    // 荷主伝票番号
    txt_slipNo: "",
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    // 有償/無償
    isPaid: "1",
    // 入荷先
    arrivalSelected: "all",
    // 取引先
    suppliersSelected: "all",
    // メニュー
    openMenu: null,
    // 入荷予定No.
    txt_arrivalScheduleNo: "",
    // 入荷予定日付キャレンダー
    dateMenu: false,
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
    },
    // ヘッダ
    headerItems: [
      {
        text: i18n.tc("label.lbl_productCodeName"),
        value: "productNm",
        width: "10%",
        align: "left",
        sortable: false,
      },
      {
        text: "製品付帯",
        value: "productAcc",
        width: "10%",
        align: "left",
        sortable: false,
      },
      { text: "入荷予定数", value: "productQty", width: "10%", align: "right", sortable: false },
      { text: "ケース（荷姿）数", value: "caseSu", width: "10%", align: "right" },
      { text: "ボール（中箱）数", value: "ballSu", width: "10%", align: "right" },
      { text: "バラ（端数）", value: "baraSu", width: "10%", align: "right" },
      { text: "ロット", value: "lot", width: "10%", align: "left" },
      { text: "賞味期限", value: "expireDate", width: "10%", align: "left" },
      { text: "付帯情報", value: "accInfos", width: "10%", align: "left" },
      { text: "格納予定ロケーション登録", value: "location", width: "10%", align: "center" },
    ],
    inputList: [],
    dummy_supplierList: [
      { text: "ALL", value: "all" },
      { text: "取引先1", value: "supplier1" },
      { text: "取引先2", value: "supplier2" },
      { text: "取引先3", value: "supplier3" },
      { text: "取引先4", value: "supplier4" },
      { text: "取引先5", value: "supplier5" },
    ],
    dummy_arrivalList: [
      { text: "ALL", value: "all" },
      { text: "入荷先1", value: "arrival1" },
      { text: "入荷先2", value: "arrival2" },
      { text: "入荷先3", value: "arrival3" },
      { text: "入荷先4", value: "arrival4" },
      { text: "入荷先5", value: "arrival5" },
    ],
    dummy_input: {
      productNm: "製品名",
      productCd: "a",
      productAcc: "製品付帯",
      productQty: "0",
      caseSu: "0",
      ballSu: "0",
      baraSu: "0",
      lot: "lot",
      expireDate: "2022/01/13",
    },
    defaultItem: {
      productNm: "",
      productAcc: "製品付帯",
      productQty: "0",
      caseSu: "0",
      ballSu: "0",
      baraSu: "0",
      lot: "lot",
      expireDate: "2022/01/13",
    },
    productList: [
      { text: "", value: "" },
      { text: "新製品", value: "product0" },
      { text: "製品1", value: "product1" },
      { text: "製品2", value: "product2" },
      { text: "製品3", value: "product3" },
      { text: "製品4", value: "product4" },
    ],
    rules: {
      yyyymmdd: (value) => dateTimeHelper.validDate(value) || "日付が間違っています",
    },
  }),
  methods: {
    // 検索ボータン
    search() {
      for (var i = 0; i < 100; i++) {
        var accInfos = [];
        var data = {
          productNm: "productNm" + i,
          productCd: "productCd" + i,
          productAcc: "productAcc" + i,
          productQty: i,
          caseSu: i,
          ballSu: i * 10,
          baraSu: i * 100,
          lot: "lot" + i,
          expireDate: "expireDate",
          accInfo01: "info01_" + i,
          accInfo02: "info02_" + i,
          accInfo03: "info03_" + i,
          accInfo04: "info04_" + i,
          accInfo05: "info05_" + i,
          accInfo06: "info06_" + i,
          accInfo07: "info07_" + i,
          accInfo08: "info08_" + i,
          accInfo09: "info09_" + i,
          accInfo10: "info10_" + i,
          accInfo11: "info11_" + i,
          accInfo12: "info12_" + i,
          accInfo13: "info13_" + i,
          accInfo14: "info14_" + i,
          accInfo15: "info15_" + i,
          accInfo16: "info16_" + i,
          accInfo17: "info17_" + i,
          accInfo18: "info18_" + i,
          accInfo19: "info19_" + i,
          accInfo20: "info20_" + i,
          accInfos: "",
        };
        for (var j = 1; j <= 20; j++) {
          var idx = j < 10 ? "0" + j : j;
          accInfos.push(data["accInfo" + idx]);
        }
        data.accInfos = accInfos.join("\n");
        this.inputList.push(data);
      }
    },
    // 初期化
    init() {
      this.arrivalSelected = "all";
      this.suppliersSelected = "all";
      this.txt_arrivalScheduleNo = "";
      this.txt_slipNo = "";
      this.inputList = [];
    },

    // メニューダイアログ
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 与えられた日付から年月日を文字列で返します。
     */
    dateCalc(date) {
      const dt = new Date(date);
      return dt.toISOString().substr(0, 10);
    },
    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}/${month}/${day}`;
    },
    // ロケーション登録
    addLocation(item) {
      this.editedIndex = this.inputList.indexOf(item);
      this.dummy_input = Object.assign({}, item);
      if (this.dummy_input.warehouse == undefined) {
        this.locInfo.warehouse = "";
        this.locInfo.location = "";
        this.locInfo.productCd = "";
        this.locInfo.qty = 0;
        this.locInfo.init = true;
      } else {
        this.locInfo.warehouse = this.dummy_input.warehouse.warehouse;
        this.locInfo.location = this.dummy_input.warehouse.location;
        this.locInfo.productCd = this.dummy_input.warehouse.productCd;
        this.locInfo.qty = this.dummy_input.warehouse.qty;
        this.locInfo.init = false;
      }
      this.locInfo.locProductCd = this.dummy_input.productCd;
      this.dialogLocation = true;
    },
    // ロケーション保存
    saveLocation(data) {
      this.dummy_input.warehouse = data;
      Object.assign(this.inputList[this.editedIndex], this.dummy_input);

      this.dialogLocation = false;
    },
    changeDate() {
      if (this.arrivalScheduleDate.length == 8) {
        var dt = this.arrivalScheduleDate;
        this.arrivalScheduleDate = dt.substr(0, 4) + "/" + dt.substr(4, 2) + "/" + dt.substr(6, 2);
      }
      dateTimeHelper.validDate(this.arrivalScheduleDate);
    },
    formValidation() {
      this.$refs.searchForm.validate();
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    arrivalScheduleDateCal: function () {
      var aa = this.dateCalc(dateTimeHelper.convertJstDateTime(this.arrivalScheduleDateCal));
      this.arrivalScheduleDate = this.formatDate(aa);
    },
  },

  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
#sub-bar {
  color: black;
  background: $color-obj-base;
  padding: 0;
  height: 3rem;
  #lbl-screen-name {
    padding: 0.1rem 0 0 0.5rem;
    font-weight: bold;
    display: inline-block;
  }
  .right {
    text-align: right;
  }
}
.menu-btn {
  margin: 0.1rem 0.2rem;
  // font-size: $menu-fontsize !important;
  font-weight: bold;
}
::v-deep #btn-logout {
  img {
    width: 20px;
    height: 20px;
  }
}
.v-btn.v-btn--has-bg {
  background: $color-obj-base;
  &.api-btn {
    width: 7rem;
  }
}

button {
  &.v-btn {
    &.day-ctrl-btn {
      padding: 0;
      width: 2rem;
      min-width: 2.5rem;
    }
    &.day-ctrl-now-btn {
      padding: 0;
      width: 4rem;
      min-width: 4rem;
    }
  }
}
.list-single ::v-deep {
  div.v-input__slot {
    border-radius: 0.4rem;
  }
}
.list-single ::v-deep,
.list-multi ::v-deep {
  div.v-input__icon {
    background: #95b3d7;
    border-radius: 0.3rem;
  }
}
.list-single ::v-deep,
.txt-single ::v-deep {
  z-index: 50;
  padding-top: 0;
  padding-right: 0;
}
#listData {
  &.v-data-table--fixed-header ::v-deep {
    th {
      background: #82b1ff;
    }
  }
}
</style>
